import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'account-deletion/:token',
    loadChildren: () =>
      import('./account-deletion/account-deletion.module').then(
        (m) => m.AccountDeletionModule
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./account-deletion/account-deletion.module').then(
        (m) => m.AccountDeletionModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
